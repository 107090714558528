import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import './App.css';
import { Drag } from "./components/Drag";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { Subscribe } from "./components/Subscribe";
import {Links} from "./components/Links";

class App extends React.Component<any, any> {


  render() {

    const track = () => {
      ReactPixel.init('');
      ReactPixel.pageView();
      TagManager.initialize({ gtmId: '' });
    }

    const handleAcceptCookie = () => {
      track();
    }

    if (getCookieConsentValue()) {
      track();
    }

    return (
      <Router>
        <Header/>
        <div className="form">

          <Switch>

            <Route path="/om">
              <ScrollToTop/>
              <Links></Links>
            </Route>

            <Route path="/">
              <Drag></Drag>
            </Route>
          </Switch>
          <div className="subsribeform">
            <Subscribe/>
          </div>
        </div>
        <Footer/>
        <CookieConsent buttonText="Jag godkänner"
                       declineButtonText="jag godkänner inte"
                       enableDeclineButton
                       onAccept={handleAcceptCookie}
                       buttonClasses="cookiebtn"
                       disableButtonStyles={true}
                       containerClasses="cookiecontainer"
                       style={{alignItems: "center"}}
                       contentClasses="cookiecontent">
          <h6>Denna webbplats använder cookies</h6>
          <p>Vi och tredjeparterna Facebook och Google placerar ut cookies på webbplatsen för att samla statistik om hur webbplatsen används och för marknadsföringsändamål om du lämnar ditt godkännande till sådan användning av cookies.</p>
          <p>Du kan läsa mer om cookies i vår cookiepolicy och om hur vi behandlar personuppgifter i samband med användning av cookies i vår <a href="https://moderaterna.se/gdpr" target="_blank" rel="noreferrer">integritetspolicyn</a> innan du gör dina val.</p>
        </CookieConsent>
      </Router>
    );
  }
}

export default App;
