import "./Drag.css";
import calc from "../calc.svg";
import nurseimg from "../nurse.svg";
import React from 'react';


export class Drag extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            year: 0,
            crime: '',
            total: 0,
            costDead: 100000,
            costInjured: 280000
        };
    }

    sexCrime(year: number = 2021) {
        let _total = 0;
        switch (year) {
            case 2021:
                _total =  212520000
                break;
        }

        this.setState({
            year: year,
            crime: 'sexualbrott som klassas som våldtäkt',
            total: _total
        })
    }

    shooting(year: number = 2021) {
        let _total = 0;
        switch (year) {
            case 2021:
                _total = 36700000
                break;
        }

        this.setState({
            year: year,
            crime: 'skjutningar',
            total: _total
        })
    }
    formatSEK(x: number) {
        let _x =Math.ceil(x);
        return _x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    render() {

        const nurse = 500000;
        const iva = 45000;
        const birth = 31000;
        const breastCancer = 69359;
        const kneeOperation = 77000;

        return (

            <div className="col-row">
                <div className="col">
                    <h3>Välj brott</h3>
                    <div className="crimes">
                        <button onClick={() => this.shooting(2021)}>
                            Skjutningar 2021
                        </button>
                        <button onClick={() => this.sexCrime(2021)}>
                            Våldtäkter 2021
                        </button>
                    </div>

                </div>
                <div className="col calc"><img src={calc}></img></div>
                <div className="col">
                    { (this.state.total === 0 ) ? (
                        <div>
                            <h3></h3>
                            <p></p>
                        </div>
                    ) : (
                        <div>
                            <h3>Kostnaden motsvarar (något av)</h3>

                            <p><span className="bold">{Math.ceil(this.state.total / nurse)}</span> undersköterskor</p>
                            <p><span className="bold">{Math.ceil(this.state.total / birth)}</span> förlossningar </p>
                            <p><span className="bold">{Math.ceil(this.state.total / iva)}</span> IVA-platser </p>
                            <p><span className="bold">{Math.ceil(this.state.total / breastCancer)}</span> bröstcanceroperationer </p>
                            <p><span className="bold">{Math.ceil(this.state.total / kneeOperation)}</span> knäoperationer </p>

                        </div>

                    )
                    }
                    <div className="nurse-img"><img src={nurseimg}></img></div>

                </div>
            </div>
        );
    }
}
