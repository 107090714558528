import React from "react";
import "./Links.css";

export class Links extends React.Component<any, any> {
  render() {
    return (
        <div className="header">
            <div className="row ">
                <h2 className="lr">Om kampanjen</h2>
                <p>Vårdkalkylatorn är en kampanj finansierad av Moderaterna i Region Stockholm. Syftet är att illustrera hur mycket brottsligheten kostar sjukvården. Kalkylatorn beskriver enbart de direkta kostnaderna som uppstår i vården av viss brottslighet. Sannolikt är de samhälleliga kostnaderna för nämnda brott betydligt högre än så. I våra exempel har vi utgått från att ett besök på en akutmottagning för våldtagna kostar ca 23 000 kronor, att en knäoperation……</p>

            </div>
    </div>
    );
  }
}
