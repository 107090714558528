import "./Header.css";
import header from "../header.png";

export const Header = () => {
  return (
    <div className="header">
        <div className="row">
            <h1>Vårdkalkylatorn</h1>
            <p className="center">Så mycket kostar brottsligheten för sjukvården</p>
        </div>

    </div>
  );
}
