import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../logo.svg";
import React from "react";

export const Footer = () => {
  return (
      <div className="">
          <div className="footer">
              <div className="menu">
                  <ul>
                      <li><Link to="/">Start</Link></li>
                      <li><Link to="/om">Om kampanjen</Link></li>
                  </ul>
              </div>
          </div>
          <div className="footer-b">
              <div className="image">
                  <img src={logo} alt="M-logo" />
              </div>
              <div className="footer-b-content">
                  <h4>En kampanjsida från Moderaterna i Stockholms stad och län</h4>
              </div>
          </div>
      </div>
  );
}
